/**
 * External dependencies
 */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { PropertValuationURL } from "../utils"

/**
 * Internal dependencies
 */
import NavUtils from "../nav-utils/nav-utils";
import ContactForm from "../../blocks/contact-form/valuation-property-form";
import "../contact-info-property-valuation/contact.scss";

const ContactValuationInfo = (props) => {
  const getOffset = el => {
    var _x = 0
    var _y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft
      _y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: _y, left: _x }
  }

  const scrollHandle = (e) => {
    e.preventDefault();
    const element = document.querySelector(`#contact-map`);
    const offestTop = getOffset(element).top;
    const stickyHeight = document.querySelector('.page-contact').clientHeight;

    window.scrollTo({
      top: stickyHeight - offestTop - 1500,
      behavior: 'smooth'
    });
  }

  return (
      <div className="shells1">     
              <ContactForm
                teamid={props.teamid}
                teamdata={props.teamdata}
                formtype={props.formtype}
                messagelabel={props.messagelabel}
                messageplaceholder={props.messageplaceholder}
                subject={props.subject}
              />
        </div>
  )
}

export default ContactValuationInfo;
