import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeIntro from "../components/home-intro/home-intro"
import HomeHelp from "../components/home-help/home-help"
import HomeText from "../components/home-text/home-text"
import HomeBook from "../components/home-book/home-book"
import HomeFeedback from "../components/home-feedback/home-feedback"
import HomeNews from "../components/home-news/home-news"
import TileBlocks from "../components/property-cols/property-cols"
import ImageBlocks from "../components/property-cols/image-block"
import FindHome from "../components/property-find/property-find"
import PropertyValuation from "../components/property-find/property-valuation"
import CategoryBlog from "../components/home-news/category-blog-slider"
import SubTiles from "../components/home-news/sub-tiles"
import AccordionBlock from "../components/property-prepare/property-prepare"
import StaticPage from "../components/property-details/static-content"
import CenterIntro from "../components/contact-intro/center-intro"
import CenterValuationIntro from "../components/property-valuation-intro/property-valuation-intro"
import TeamListing from "../components/property-videos/team-listing"
import NewsListing from "../components/home-news/NewsListing"
import AreaListing from "../components/area-guides/area-guides-listing"
import DetailMap from "../components/map/detail-map"
import SociallWall from "../components/social-wall"
import ReviewVideos from "../components/property-videos/property-videos"
import ReviewsList from "../components/property-reviews/property-reviews"
import ContactForm from "../components/contact-info/contact-info"
import ContactValuationInfo from "../components/contact-info-property-valuation/contact-info"
import ContactMap from "../components/map/contact-map"
import ValuationIntro from "../components/contact-book/contact-book";
import BannerVideo from "../images/Lamborn-hill-Home-page-video.mp4"
import Stats from "../components/stats"
import { MemberContactDetail } from '../queries/common_use_query'
import { useLocation } from "@reach/router";
import { parse } from "query-string";
const IndexPage = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  // Get Team Detail
  const location = useLocation();    
  const searchParams = parse(location.search) // => {init: "true"}
  const pathname = location.pathname.replace(/\//g, "")
  const teamid = searchParams.tid
  const {loading, error, data} = MemberContactDetail(teamid);
  // Get Team Detail

  return (
    <Layout bannerimage={GQLPage.Banner_Image} pagename={GQLPage.Title} pageclass={GQLPage.Page_Class} layout={GQLPage.Layout} popularsearch={GQLPage.Select_Popular_Search}>
      {GQLPage.Banner_Title != "" && GQLPage.Layout != "Center" && GQLPage.Layout != "Valuation" && (
        <HomeIntro
          pagename={GQLPage.Title}
          layout={GQLPage.Layout}
          title={GQLPage.Banner_Title}
          content={GQLPage.Banner_Description}
          ctas={GQLPage.Banner_CTA}
          reviews={GQLPage.Banner_Reviews}
          bannerimage={GQLPage.Banner_Image}
          bannerVideo={BannerVideo}
          imagetransforms={GQLPage.imagetransforms}
          id={GQLPage.id}
        />
      )}
      {GQLPage.Banner_Title != "" && GQLPage.Layout === "Center" && (
    typeof window !== "undefined" && window.location.pathname === "/expert-property-valuation/" ? (
    <CenterValuationIntro
      pagename={GQLPage.Title}
      title={GQLPage.Banner_Title}
      content={GQLPage.Banner_Description}
      reviews={GQLPage.Banner_Reviews}
      teamid={teamid}
      teamdata={data}
    />
  ) : (
    <CenterIntro
      pagename={GQLPage.Title}
      title={GQLPage.Banner_Title}
      content={GQLPage.Banner_Description}
      reviews={GQLPage.Banner_Reviews}
      teamid={teamid}
      teamdata={data}
    />
  )
)}

      {GQLPage.Banner_Title != "" && GQLPage.Layout === "Valuation" && (
        <ValuationIntro title={GQLPage.Banner_Title} content={GQLPage.Banner_Description} bannerimage={GQLPage.Banner_Image} ctas={GQLPage.Banner_CTA}/>
      )}
      {GQLModules.map((Modules, i) => {
        return (
          <>
            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "What_do_you_need_module" && (
                <HomeHelp pageTitle={GQLPage.Title} />
              )}

            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsShortIntro" && (
              <HomeText
                content={Modules.Short_Intro_Content}
                label={Modules.Intro_CTA_Label}
                cta={Modules.Intro_CTA_Link}
                sidebar={Modules.Enable_Sidebar_Contact}
              />
            )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Book_a_home_valuation_module" && (
                <HomeBook title={GQLPage.Title} video={GQLPage.Book_an_appointment_Video_Upload} image={GQLPage.Book_an_appointment_Video_Image}/>
              )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Reviews_module" && (
                <HomeFeedback title={GQLPage.Title} />
              )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Latest_news_module" && <HomeNews />}
            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsTileBlocks" && (
              <TileBlocks
                title={Modules.Title}
                description={Modules.Description}
                image={Modules.Tile_Image}
                link={Modules.Tile_CTA_Link}
                iconlist={Modules.Icon_List}
                video={Modules.Tile_Video_URL}
              />
            )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Find_your_next_home_module" && (
                <FindHome />
              )}

            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsCategorizedBlogs" && (
              <CategoryBlog
                title={Modules.Module_Title}
                category={Modules.Select_Category}
              />
            )}

            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsSubImageTiles" && (
              <SubTiles
                title={Modules.Block_Title}
                tiles={Modules.Sub_Thumbnails}
              />
            )}
            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsStatisticsModule" &&(
              <Stats
                heading={Modules.Stats_Module_Heading}
                caption={Modules.Stats_Module_Caption}
                stats ={Modules.Stats_List}
              />
            )}
            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsAccordionTileBlock" && (
              <AccordionBlock
                title={Modules.Tile_Block_Title}
                image={Modules.Tile_Block_Image}
                description={Modules.Tile_Block_Description}
                list={Modules.Accordion_List}
              />
            )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Property_video_valuation_module" && (
                <PropertyValuation />
              )}

            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsImageBlock" && (
              <ImageBlocks
                image={Modules.Image_Tile}
                description={Modules.Block_Content}
              />
            )}

            {Modules.__typename ===
              "GLSTRAPI_ComponentComponentsStaticPage" && (
              <StaticPage content={Modules.Static_Content} />
            )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Team_listing_module" && (
                <TeamListing />
              )}

            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "News_listing_module" && (
                <NewsListing />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Social_wall_module" && (
                <SociallWall />
              )}
              {/* {Modules.__typename ===
                "GLSTRAPI_ComponentComponentsReviewVideoTile" && (
                <ReviewVideos item={Modules.Review_Video_Tile} />
              )} */}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Review_listing_module" && (
                  <ReviewsList />
              )}
            {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Areaguides_listing_module" && (
                <AreaListing />
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" &&
              Modules.Select_Form === "Contact_Form" && (
                <ContactForm teamid = {teamid} teamdata = {data}/>
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" &&
              Modules.Select_Form === "Property_Valuation_Form" && (
                <ContactValuationInfo teamid = {teamid} teamdata = {data}/>
              )}
              {Modules.__typename === "GLSTRAPI_ComponentComponentsForms" &&
              Modules.Select_Form === "Report_Form" && (
                <ContactForm formtype="Report a maintenance" messagelabel="Issue" messageplaceholder="Enter your issue here" subject="Report a maintenance"/>
              )}
              {/* {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" &&
              Modules.Select_Module === "Contact_map_module" && !teamid && (
                <ContactMap  />
              )} */}
          </>
        )
      })}
      {pathname == "contact" && !teamid &&
        <ContactMap  latitude={51.3406355} longitude={0.7293329} />
      }
    </Layout>
  )
}

export default IndexPage
