
import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { Link } from "gatsby"

import "./styles/_index.scss"
import Button from "../../components/button/button"
import { isMobile } from "../../lib/media"
import ContactPageForm from "../../components/forms/expert-valuation";
import { StaticImage } from "gatsby-plugin-image";
const schema = yup.object().shape({
  fullName: yup.string().required("Please enter your full name"),
  email: yup
    .string()
    .email("Invalid email. Example: someexample@mail.com")
    .required("Please enter you email"),
  phone: yup.string().required("Please enter you phone number"),
  message: yup.string().min(5, "Message must be at least 5 symbols."),
})

const ContactvaluationForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onFormSubmit = data => data

  return (
    <div className="form val-contact-form">
    <div className="centered-image-container">
  <img 
    className="centered-image"
    src="https://ggfx-lambornhill.s3.eu-west-2.amazonaws.com/i.dev/logo_prop_valuation_8776c696c6.jpg"
    alt="Book a Viewing"
  />
</div>
      {props.teamid && props.teamdata && props.teamdata.teams.length > 0 ?
        <ContactPageForm to_email_id={props.teamdata.teams[0].Email} formtype="Staff Contact" subject="Staff"/>
      :
        <ContactPageForm subject={props.subject} formtype={props.formtype} messagelabel={props.messagelabel} messageplaceholder={props.messageplaceholder} />
      }

    </div>
  )
}

export default ContactvaluationForm
