/**
 * External dependencies
 */
import React from "react";
import HTMLReactParser from 'html-react-parser';
import Reviews from "../reviews/reviews";

/**
 * Internal dependencies
 */
import "../contact-intro/styles/_index.scss";
import NewsletterForm from "../property-find/newsletter-form";
import Breadcrumbs from "../breadcrumbs";

const CenterValuationIntro = (props) => {
  return (
      <div className="shells11">

           {props.pagename === "News" &&
             <NewsletterForm />
           }   
           {props.reviews &&
             <Reviews className="reviews--row banner-center-reviews" />
           }       
      </div>
      
  )
}

export default CenterValuationIntro;
